var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "modal-container" }, [
    _c("div", { staticClass: "modal-wrap" }, [
      _c("div", { staticClass: "dialog-header" }, [
        _c("div", { staticClass: "dialog-title" }, [_vm._v("Start Sprint ")]),
        _c(
          "div",
          { staticClass: "close-btn", on: { click: _vm.closeDialog } },
          [
            _c("img", {
              attrs: { src: "/img/onboarding/cancel_close.svg", alt: "" },
            }),
          ]
        ),
      ]),
      _c("div", { staticClass: "dialog-content" }, [
        _c("div", { staticClass: "dialog-msg" }, [
          _vm._v(
            "\n        Select start date for Sprint 1. Each sprint has a 1 week cycle, we\n        will use the start date you set here to plan subsequent sprints.\n      "
          ),
        ]),
        _c("div", { staticClass: "date-container" }, [
          _c(
            "div",
            { staticClass: "start-date-div" },
            [
              _c("div", { staticClass: "form-label" }, [_vm._v("Start date")]),
              _c(
                "v-menu",
                {
                  ref: "element",
                  attrs: {
                    "close-on-content-click": false,
                    "return-value": _vm.form.start_date,
                  },
                  on: {
                    "update:returnValue": function ($event) {
                      return _vm.$set(_vm.form, "start_date", $event)
                    },
                    "update:return-value": function ($event) {
                      return _vm.$set(_vm.form, "start_date", $event)
                    },
                  },
                  model: {
                    value: _vm.form.startMenu,
                    callback: function ($$v) {
                      _vm.$set(_vm.form, "startMenu", $$v)
                    },
                    expression: "form.startMenu",
                  },
                },
                [
                  _c(
                    "div",
                    { attrs: { slot: "activator" }, slot: "activator" },
                    [
                      _c("input", {
                        directives: [
                          {
                            name: "model",
                            rawName: "v-model",
                            value: _vm.form.start_date,
                            expression: "form.start_date",
                          },
                        ],
                        staticClass: "date_textbox  disabled-end-date",
                        attrs: {
                          readonly: "",
                          type: "text",
                          placeholder: "Select Date",
                        },
                        domProps: { value: _vm.form.start_date },
                        on: {
                          input: function ($event) {
                            if ($event.target.composing) {
                              return
                            }
                            _vm.$set(
                              _vm.form,
                              "start_date",
                              $event.target.value
                            )
                          },
                        },
                      }),
                    ]
                  ),
                ]
              ),
            ],
            1
          ),
          _c("div", { staticClass: "seperator-div" }, [_vm._v("-")]),
          _c("div", { staticClass: "end-date-div" }, [
            _c("div", { staticClass: "form-label" }, [_vm._v("End date")]),
            _c("input", {
              directives: [
                {
                  name: "model",
                  rawName: "v-model",
                  value: _vm.form.end_date,
                  expression: "form.end_date",
                },
              ],
              staticClass: "disabled-end-date",
              attrs: { type: "text" },
              domProps: { value: _vm.form.end_date },
              on: {
                input: function ($event) {
                  if ($event.target.composing) {
                    return
                  }
                  _vm.$set(_vm.form, "end_date", $event.target.value)
                },
              },
            }),
          ]),
        ]),
      ]),
      _c("div", { staticClass: "dialog-footer" }, [
        _c(
          "button",
          {
            staticClass: "grupa-grey-btn",
            staticStyle: { "margin-right": "24px" },
          },
          [_vm._v("\n        Cancel\n      ")]
        ),
        _c(
          "button",
          {
            staticClass: "grupa-disabled-btn",
            class: _vm.enableBtn ? "grupa-blue-btn" : "",
            attrs: { disabled: !_vm.enableBtn },
            on: { click: _vm.handleStartSprint },
          },
          [
            _vm._v("\n        Proceed\n        "),
            _c("img", {
              directives: [
                {
                  name: "show",
                  rawName: "v-show",
                  value: _vm.start_spinner,
                  expression: "start_spinner",
                },
              ],
              staticClass: "button-spinner",
              staticStyle: { "margin-left": "20px" },
              attrs: { src: "/img/lightbox/preloader.gif" },
            }),
          ]
        ),
      ]),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }