<template>
  <div class="assign-team-task">
    <!--  -->

    <div class="prompt-header">
      Assign Task
      <img
        src="/img/lightbox/preloader.gif"
        style="float: right"
        class="button-spinner"
        v-show="spinner"
      />
    </div>
    <div
      class="members-list-task"
      v-for="(member, i) in teamMembers"
      :key="i"
      @click="handleAssignTask(member)"
      v-if="member.team_role != 'Product Manager' && member.name"
    >
      <div style="display: flex">
        <!-- team_role: "Product Designer" -->
        <div
          v-if="member.team_role == 'Product Designer'"
          style="display: flex"
        >
          <div>
            <img src="/img/dashboard/task/design.svg" />
          </div>
          <div class="member-task">Design</div>
        </div>
        <!-- team_role: "QA Engineer" -->
        <div
          v-else-if="
            member.team_role == 'QA Engineer' || member.team_role == 'QA/QC'
          "
          style="display: flex"
        >
          <div>
            <img src="/img/dashboard/task/testing.svg" />
          </div>
          <div class="member-task">QA Test</div>
        </div>
        <!-- team_role: Others -->
        <div v-else style="display: flex">
          <div>
            <img src="/img/dashboard/task/development.svg" />
          </div>
          <div class="member-task">Development</div>
        </div>
        -
        <div class="profile-icon">
          <v-avatar
            :size="24"
            color="grey lighten-4"
            class="profile_bg"
            :style="{
              backgroundImage: `url(${
                $imageUrl + member.profile_picture || test
              })`,
            }"
          ></v-avatar>
        </div>
        <div class="member-task">{{ shortenName(member.name) }}</div>
      </div>
      <div
        v-if="
          currentTask.teams.length > 0 &&
          currentTask.teams[currentTask.teams.length - 1].dev_id == member.id
        "
      >
        <img src="/img/dashboard/task/checkmark.svg" />
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions, mapState } from "vuex";
import { devAssignTask } from "@/api/team";
import shortName from "@/mixins/shortName";
import { taskCategory } from "@/utils/taskCategory";
import appMixin from "@/mixins/appMixin";
export default {
  mixins: [shortName, appMixin],
  props: {},
  components: {},

  data: () => ({
    profile: "/img/onboarding/company/profile.jpeg",
    currentHover: "",
    selected: false,
    spinner: false,
  }),

  mounted() {},
  methods: {
    ...mapActions("alertMsg", ["setSuccessMsg", "setErrorMsg"]),
    ...mapActions("devDashboard", ["updateProjectTask"]),
    async handleAssignTask(developer) {
      this.spinner = true;
      let task_id = this.currentTask.id;
      let { team_role, id: dev_id } = developer;
      let task_type_id = taskCategory(team_role);
      let payload;
      if (this.currentTask.teams.length > 0) {
        let user_one =
          this.currentTask.teams[this.currentTask.teams.length - 1].dev_id;
        payload = {
          replace: true,
          task_type_id,
          task_id,
          user_one,
          user_two: dev_id,
          date_timezone: this.currentDate(),
        };
      } else {
        payload = {
          task_type_id,
          task_id,
          dev_id,
          date_timezone: this.currentDate(),
        };
      }
      let taskList = JSON.parse(JSON.stringify(this.tasks));
      await devAssignTask(payload)
        .then((res) => {
          if (res.data) {
            let taskObj = res.data.data.task;
            this.setSuccessMsg("Task Assigned!!");
            let taskIndex = taskList.findIndex((task) => task.id == task_id);
            taskList[taskIndex] = taskObj;
            this.updateProjectTask(taskList);
            this.spinner = false;
            this.$emit("closePopup");
          }
        })
        .catch((err) => {
          this.setErrorMsg("Please try again!!");
          this.$developConsole(err);
        });
    },
  },
  computed: {
    ...mapState("devDashboard", ["selectedProjectDoc", "teamInfo"]),
    ...mapState("popupManagement", ["currentTask"]),
    teamMembers() {
      return this.teamInfo.team.members;
    },
    tasks() {
      return this.selectedProjectDoc.tasks;
    },
  },
};
</script>
<style scoped>
* {
  font-family: Moderat;
  font-style: normal;
  font-weight: normal;
}
.assign-team-task {
  background: #ffffff;
  border: 1px solid #e4e5e7;
  box-sizing: border-box;
  box-shadow: 2px 5px 15px rgba(27, 30, 34, 0.07);
  border-radius: 4px;
  /* width: 317px; */
  width: 364px;
}

.prompt-header {
  font-size: 14px;
  line-height: 130%;
  color: #1b1e22;
  padding: 24px 24px 12px;
  text-align: left;
}
.members-list-task {
  padding: 9px 24px;
  cursor: pointer;
  display: flex;
  justify-content: space-between;
}
.members-list-task:hover {
  background: #f7f7f8;
}
.member-task {
  font-size: 14px;
  line-height: 130%;
  color: #53585f;
  margin: 4px 16px 0px 8px;
}
.members-list-task .profile-icon {
  margin-left: 16px;
}
</style>
