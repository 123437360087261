export const taskCategory = (role) => {
  if (role == "Product Designer") {
    return 2;
  } else if (role == "QA Engineer" || role == "QA/QC") {
    return 3;
  } else {
    return 1;
  }
};

export const taskStage = (id) => {
  if (id == 2) {
    return "design";
  } else if (id == 3) {
    return "testing";
  } else {
    return "development";
  }
};
