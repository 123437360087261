import moment from "moment";
import businessMoment from "moment-business-days";

export const compareDateWithNow = (date) => {
  return moment(date).isBefore(moment());
};

export const compareTwoDates = (date1, date2) => {
  return moment(date1).isBefore(moment(date2));
};
export const addDaysFromDate = (date, days) => {
  let resultDate = moment(date, "YYYY-MM-DD").add(days, "days");
  return resultDate.format("YYYY-MM-DD");
};
export const dateDiffInDays = (date1, date2) => {
  let firstDate = moment(date1);
  let secondDate = moment(date2);
  return firstDate.diff(secondDate, "days");
};

export const formatDayMonth = (date) => {
  return moment(date).format("Do MMM");
};
export const businessDaysDiffInDays = (date1, date2) => {
  var diff = businessMoment(date1, "YYYY-MM-DD").businessDiff(
    businessMoment(date2, "YYYY-MM-DD")
  );
  return diff;
};

export const getWorkDays = (n) => {
  if (n < 6) {
    return n;
  } else if (n == 6) {
    return n - 1;
  } else if (n >= 7 && n <= 12) {
    return n - 2;
  } else if (n == 13) {
    return n - 3;
  } else if (n >= 14 && n <= 19) {
    return n - 3;
  }
};
