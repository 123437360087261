<template>
  <div class="modal-container">
    <div class="modal-wrap">
      <div class="dialog-header">
        <div class="dialog-title">Start Sprint </div>
        <div class="close-btn" @click="closeDialog">
          <img src="/img/onboarding/cancel_close.svg" alt="" />
        </div>
      </div>
      <div class="dialog-content">
        <div class="dialog-msg">
          Select start date for Sprint 1. Each sprint has a 1 week cycle, we
          will use the start date you set here to plan subsequent sprints.
        </div>
        <div class="date-container">
          <div class="start-date-div">
            <!-- v-menu date-picker -->
            <div class="form-label">Start date</div>
            <v-menu
              ref="element"
              v-model="form.startMenu"
              :close-on-content-click="false"
              :return-value.sync="form.start_date"
            >
              <div class="" slot="activator">
                <input
                readonly
                  type="text"
                  v-model="form.start_date"
                  class="date_textbox  disabled-end-date"
                  placeholder="Select Date"
                />
               <!--  <img src="/img/dashboard/dev/calendar-icon.svg" /> -->
              </div>
              
               <!-- :min="todayDate" -->
              <!-- <v-date-picker
                v-model="form.start_date"
                no-title
                scrollable
                type="date"
                disabled="disabled"
              >
               
                <v-spacer></v-spacer>
                <v-btn text color="primary" @click="form.startMenu = false">
                  Cancel
                </v-btn>
                <v-btn
                  text
                  color="primary"
                  @click="$refs.element.save(form.start_date)"
                >
                  Ok
                </v-btn>
              </v-date-picker> -->
            </v-menu>
          </div>
          <div class="seperator-div">-</div>
          <div class="end-date-div">
            <div class="form-label">End date</div>
            <input
              type="text"
              v-model="form.end_date"
              
              
              class="disabled-end-date"
            />
          </div>
        </div>
      </div>
      <div class="dialog-footer">
        <button class="grupa-grey-btn" style="margin-right: 24px">
          Cancel
        </button>
        <button
          class="grupa-disabled-btn"
          :class="enableBtn ? 'grupa-blue-btn' : ''"
          :disabled="!enableBtn"
          @click="handleStartSprint"
        >
          Proceed
          <img
            src="/img/lightbox/preloader.gif"
            style="margin-left: 20px"
            class="button-spinner"
            v-show="start_spinner"
          />
        </button>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    closeDialog: {
      type: Function,
      required: true,
    },
    form: {
      type: Object,
      required: true,
    },
    handleStartSprint: {
      type: Function,
      required: true,
    },
    start_spinner: Boolean,
  },
  data() {
    return {
      todayDate: new Date().toISOString().slice(0, 10),
    };
  },
  created() {
   // alert('d')
    this.form.start_date = new Date().toISOString().slice(0, 10)
  },
  methods: {},
  computed: {
    enableBtn() {
      if (this.form.start_date !== "") {
        return true;
      } else {
        return false;
      }
    },
  },
};
</script>

<style scoped>
.modal-container {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: rgba(0, 0, 0, 0.3);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

.modal-wrap {
  background: #ffffff;
  /* box-shadow: 2px 2px 20px 1px; */
  /* padding: 15px 0px 34px 0px; */
  flex-direction: column;
  min-width: 332px;
  /* min-height: 50vh; */
  box-shadow: 2px 2px 30px rgba(0, 0, 0, 0.3);
  border-radius: 5px;
}
.dialog-header {
  background: #f7f7f8;
  box-shadow: 0px 1px 2px rgba(30, 27, 34, 0.1);
  border-radius: 4px 4px 0px 0px;
  padding: 16px;
  display: flex;
  justify-content: space-between;
}
.dialog-title {
  font-weight: 500;
  font-size: 14px;
  line-height: 130%;
  text-align: left;
  color: #1b1e22;
}
.dialog-content {
  padding: 24px;
}
.dialog-footer {
  padding: 0px 16px 16px 16px;
  display: flex;
  justify-content: flex-end;
}
.close-btn {
  /* float: right; */
  cursor: pointer;
}
.dialog-msg {
  font-size: 14px;
  line-height: 130%;
  color: #1b1e22;
  text-align: left;
  width: 356px;
}
.date-container {
  display: flex;
  width: 356px;
  margin-top: 48px;
}
.form-label {
  text-align: left;
  font-size: 14px;
  line-height: 130%;
  color: #1b1e22;
}
.date_textbox {
  width: 84%;
}
.input_wrap {
  background: #ffffff;
  border: 1px solid #e4e5e7;
  box-sizing: border-box;
  border-radius: 4px;
  margin-top: 4px;
  margin-bottom: 0px;
  display: flex;
  padding: 15px 13px 12px 16px;
  height: 50px;
  width: 157px;
}
.disabled-end-date:focus,
.date_textbox:focus {
  border: 0px;
  outline: none;
}
.seperator-div {
  margin: 17px;
  margin-top: 33px;
}
.grupa-blue-btn {
  background: #0781f2 !important;
  color: #fdfdfd !important;
}
.disabled-end-date {
  background: #f7f7f8;
  border: 1px solid #e4e5e7;
  box-sizing: border-box;
  border-radius: 4px;
  height: 50px;
  width: 157px;
  margin-top: 4px;
  font-size: 14px;
  line-height: 130%;
  color: #979da5;
  padding: 16px;
}
</style>


