var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "assign-team-task" },
    [
      _c("div", { staticClass: "prompt-header" }, [
        _vm._v("\n    Assign Task\n    "),
        _c("img", {
          directives: [
            {
              name: "show",
              rawName: "v-show",
              value: _vm.spinner,
              expression: "spinner",
            },
          ],
          staticClass: "button-spinner",
          staticStyle: { float: "right" },
          attrs: { src: "/img/lightbox/preloader.gif" },
        }),
      ]),
      _vm._l(_vm.teamMembers, function (member, i) {
        return member.team_role != "Product Manager" && member.name
          ? _c(
              "div",
              {
                key: i,
                staticClass: "members-list-task",
                on: {
                  click: function ($event) {
                    return _vm.handleAssignTask(member)
                  },
                },
              },
              [
                _c("div", { staticStyle: { display: "flex" } }, [
                  member.team_role == "Product Designer"
                    ? _c("div", { staticStyle: { display: "flex" } }, [
                        _vm._m(0, true),
                        _c("div", { staticClass: "member-task" }, [
                          _vm._v("Design"),
                        ]),
                      ])
                    : member.team_role == "QA Engineer" ||
                      member.team_role == "QA/QC"
                    ? _c("div", { staticStyle: { display: "flex" } }, [
                        _vm._m(1, true),
                        _c("div", { staticClass: "member-task" }, [
                          _vm._v("QA Test"),
                        ]),
                      ])
                    : _c("div", { staticStyle: { display: "flex" } }, [
                        _vm._m(2, true),
                        _c("div", { staticClass: "member-task" }, [
                          _vm._v("Development"),
                        ]),
                      ]),
                  _vm._v("\n      -\n      "),
                  _c(
                    "div",
                    { staticClass: "profile-icon" },
                    [
                      _c("v-avatar", {
                        staticClass: "profile_bg",
                        style: {
                          backgroundImage:
                            "url(" +
                            (_vm.$imageUrl + member.profile_picture ||
                              _vm.test) +
                            ")",
                        },
                        attrs: { size: 24, color: "grey lighten-4" },
                      }),
                    ],
                    1
                  ),
                  _c("div", { staticClass: "member-task" }, [
                    _vm._v(_vm._s(_vm.shortenName(member.name))),
                  ]),
                ]),
                _vm.currentTask.teams.length > 0 &&
                _vm.currentTask.teams[_vm.currentTask.teams.length - 1]
                  .dev_id == member.id
                  ? _c("div", [
                      _c("img", {
                        attrs: { src: "/img/dashboard/task/checkmark.svg" },
                      }),
                    ])
                  : _vm._e(),
              ]
            )
          : _vm._e()
      }),
    ],
    2
  )
}
var staticRenderFns = [
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", [
      _c("img", { attrs: { src: "/img/dashboard/task/design.svg" } }),
    ])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", [
      _c("img", { attrs: { src: "/img/dashboard/task/testing.svg" } }),
    ])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", [
      _c("img", { attrs: { src: "/img/dashboard/task/development.svg" } }),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }